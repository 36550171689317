import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ringOne = require("../assets/images/ring-1.svg")
const ringTwo = require("../assets/images/ring-2.svg")

const IndexPage: React.FC = () => (
  <Layout>
    <SEO title="Home" />
    <div className="c-home">
      <h1>coming soon!</h1>
      <img className="c-home__ring-one" src={ringOne} alt="ring-one" />
      <img className="c-home__ring-two" src={ringTwo} alt="ring-two" />
    </div>
  </Layout>
)

export default IndexPage
